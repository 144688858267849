import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import { CustomerPartEntry } from '@/customer/part_details';
import { DefaultValuesCustomer } from '@/customer/defaults_customer';
import router from '@/router';

Vue.use(Vuex);

type OfferSelectionMap = Record<string, SelectionDetail>;

export interface AdditionalPartFile {
  name: string;
  uuid: string;
  size: number;
  type: string;
  progress: number;
}

export interface SelectionDetail {
  selectedIndex: number;
  comment: string;
}

export interface UUIDToFileEntry {
  [key: string]: FileEntry;
}

export interface UUIDToSetEntry {
  [key: string]: SetEntry;
}

export interface UUIDToPartEntry {
  [key: string]: PartEntry;
}

export interface FileEntry {
  file_uuid: string;
  name: string;
  parts: [string];
}

export interface SetEntry {
  set_uuid: string;
  calculation: object;
  parts: [SetPartEntry];
}

export interface SetPartEntry {
  part_uuid: string;
  count: number;
}

export interface PartEntry {
  part_uuid: string;
  features: object;
  properties: any;
  additional_files: [AdditionalPartFile];
  parts: [string];
  source_file_name: string;
  source_file_uuid: string;
}

export interface User {
  id: number;
  role: number;
  name: string;
}

interface State {
  user?: User;
  extractedParams: [];
  currentOfferSelection: OfferSelectionMap;
  currentOfferName?: string;
  currentOfferId?: number;
  currentOfferStatus?: string;
  currentOfferShipping: number;
  currentOfferDetail?: any;
  optimizationResult?: any;

  [key: string]: State[keyof State];

  currentStepCalcDialog: number;

  charge_size: number;

  processedMaterials: [];
  thicknessPerMat: {};

  defaultValues?: DefaultValues;

  files: UUIDToFileEntry;
  parts: UUIDToPartEntry;
  sets: UUIDToSetEntry;
}

function initialState(): State {
  return {
    user: undefined,
    extractedParams: [],
    currentOfferName: undefined,
    currentOfferId: undefined,
    currentOfferStatus: undefined,
    currentOfferShipping: 1,
    currentOfferSelection: {},
    optimizationResult: [],
    currentStepCalcDialog: 0,
    charge_size: 3,
    processedMaterials: [],
    thicknessPerMat: {},
    defaultValues: undefined,
    files: {},
    parts: {},
    sets: {}
  };
}

export function formatOfferId(offerId: number) {
  return zeroPad(offerId, 7);
}

function zeroPad(num: any, places: any) {
  return String(num).padStart(places, '0');
}

// USE MODULES IN FUTURE TO CLEANUP STORE
export default new Vuex.Store({
  state: initialState,
  getters: {
    optimizationResult: state => {
      return state.optimizationResult;
    },
    currentOfferDetail: state => {
      return state.currentOfferDetail;
    },
    currentOfferContact: state => {
      if (!state.currentOfferDetail) {
        return null;
      }
      return state.currentOfferDetail.offer_contact;
    },
    defaultValues: state => {
      return state.defaultValues;
    },
    extractedParams: state => {
      return state.extractedParams;
    },
    chargeSize: state => {
      return state.charge_size;
    },
    currentOfferName: state => {
      return state.currentOfferName;
    },
    currentOfferShipping: state => {
      return state.currentOfferShipping;
    },
    bexioAuthentication: state => {
      return state.bexioAuthentication;
    },
    isAuthenticated: state => {
      return state.user != null;
    },
    currentStepCalcDialog: state => {
      return state.currentStepCalcDialog;
    },
    isCustomer: (state: any) => {
      return state.user && state.user.role === 3;
    },
    hasManufacturerRights: state => {
      return state.user && state.user.role <= 2;
    },
    isAdmin: state => {
      return state.user && state.user.role <= 1;
    },
    files: state => {
      return state.files;
    },
    parts: (state): UUIDToPartEntry => {
      return state.parts;
    },
    sets: state => {
      return state.sets;
    },
    getSetGroups: state => {
      // TODO this needs rework as soon as we allow multiple parts per set
      let result = Object.entries(state.sets).reduce(function(r, [key, val]) {
        let group_key = val.parts
          .map(x => x.part_uuid)
          .sort()
          .join();
        r[group_key] = r[group_key] || { group_key: group_key, parts: [], sets: [] };
        r[group_key]['sets'].push(key);
        r[group_key]['parts'] = [...new Set([...r[group_key]['parts'], ...val.parts.map(x => x.part_uuid)])];
        return r;
      }, Object.create(null));
      return result;
    },
    getPartNameToPartUUID: state => (uuid: string) => {
      // @ts-ignore
      return state.parts[uuid].name;
    },
    getFileNameToFileUUID: state => (uuid: string) => {
      return state.files[uuid].name;
    },
    getPartsOfFileUUID: state => (uuid: string) => {
      return Object.values(state.parts)
        .filter((el: any) => {
          return el.source_file_uuid === uuid;
        })
        .map((el: any) => el.details.name);
    },
    getSetCountsOfPartUUID: state => (partUUID: string) => {
      let result = [];
      for (let currSetKey in state.sets) {
        let currSet: any = state.sets[currSetKey];
        for (let part of currSet.parts) {
          if (part.part_uuid === partUUID) {
            result.push({ count: part.count, set_uuid: currSet.set_uuid });
          }
        }
      }
      return result;
    },
    getSetsOfPartUUID: state => (partUUID: string) => {
      let result = [];
      for (let currSetKey in state.sets) {
        let currSet: any = state.sets[currSetKey];
        for (let part of currSet.parts) {
          if (part.part_uuid === partUUID) {
            result.push(currSet);
          }
        }
      }
      return result;
    },
    getSingleSetOfPartUUID: (state, getters) => (partUUID: string) => {
      let result = getters.getSetsOfPartUUID(partUUID);
      if (result.length > 1) {
        return null;
      } else {
        return result[0];
      }
    },
    containsFileName: state => (name: string) => {
      return state.parts.hasOwnProperty(name);
    },
    getCurrentOfferStatus: state => {
      return state.currentOfferStatus;
    },
    getFormattedOfferId: (state: any, getters) => {
      return formatOfferId(getters.currentOfferID);
    },
    isOfferActive: state => {
      return !!state.currentOfferId;
    },
    currentOfferID: state => {
      return state.currentOfferId;
    },
    processedMaterials: state => {
      return state.processedMaterials;
    },
    availableDrills: state => {
      return state.availableDrills;
    },
    thicknessPerMat: state => {
      return state.thicknessPerMat;
    },
    userRole: state => {
      if (state.user) return state.user.role;
      else return -1;
    },
    currentOfferSelection: state => {
      return state.currentOfferSelection;
    }
  },
  mutations: {
    resetParts: state => {
      Vue.set(state, 'parts', {});
      Vue.set(state, 'files', {});
      Vue.set(state, 'sets', {});
    },
    resetFiles: state => {
      Vue.set(state, 'files', {});
    },
    resetOffer: state => {
      Vue.set(state, 'extractedParams', []);
      Vue.set(state, 'currentOfferSelection', {});
      Vue.set(state, 'currentOfferName', undefined);
      Vue.set(state, 'currentOfferId', undefined);
      Vue.set(state, 'currentOfferDetails', undefined);
      Vue.set(state, 'parts', {});
      Vue.set(state, 'files', {});
      Vue.set(state, 'sets', {});
      Vue.set(state, 'processedMaterials', []);
      Vue.set(state, 'thicknessPerMat', {});
      Vue.set(state, 'optimizationResult', []);
      Vue.set(state, 'currentStepCalcDialog', 0);
    },
    setCurrentStepCalcDialog: (state, step_number) => {
      Vue.set(state, 'currentStepCalcDialog', step_number);
    },
    setUser: (state, user) => {
      Vue.set(state, 'user', user);
    },
    setOptimizationResult: (state, result) => {
      Vue.set(state, 'optimizationResult', result);
    },
    logoutUser: state => {
      const s = initialState();
      // @ts-ignore
      window.$cookies.remove('jwt_header_payload');
      // @ts-ignore
      Object.keys(s).forEach(key => {
        Vue.set(state, key, s[key]);
      });
    },
    setDefaultValues: (state, { disclaimer, maxShortLength, maxLongLength, offerValidityDays }) => {
      state.defaultValues = new DefaultValuesCustomer(disclaimer, maxLongLength, maxShortLength, offerValidityDays);
    },
    addFileEntry: (state, { fileUUID, fileEntry }) => {
      Vue.set(state.files, fileUUID, fileEntry);
    },
    addPartEntry: (state, { partUUID, partEntry }) => {
      Vue.set(state.parts, partUUID, partEntry);
    },
    addParts: (state, parts) => {
      let new_parts = { ...state.parts, ...parts };
      Vue.set(state, 'parts', new_parts);
    },
    addSetEntry: (state, { setUUID, setEntry }) => {
      Vue.set(state.sets, setUUID, setEntry);
    },
    addSets: (state, sets) => {
      let new_sets = { ...state.sets, ...sets };
      Vue.set(state, 'sets', new_sets);
    },
    deleteFileEntry: (state, fileUUID) => {
      Vue.delete(state.files, fileUUID);
    },
    deletePartEntry: (state, partUUID) => {
      Vue.delete(state.parts, partUUID);
    },
    deleteSetEntry: (state, { setUUID }) => {
      Vue.delete(state.sets, setUUID);
    },
    setPartUUID: (state, { name, uuid }) => {
      Vue.set(state.parts[name], 'uuid', uuid);
    },
    updatePartProperties: (state, { name, properties }) => {
      for (const prop_name of Object.keys(properties)) {
        Vue.set(state.parts[name], prop_name, properties[prop_name]);
      }
    },
    updatePartOrder: (state, { name, counts, material, thickness, bends, threads, countersinks, finish }) => {
      Vue.set(state.parts[name], 'counts', counts);
      Vue.set(state.parts[name], 'material', material);
      Vue.set(state.parts[name], 'thickness', thickness);
      Vue.set(state.parts[name], 'bends', bends);
      Vue.set(state.parts[name], 'finish', finish);
      Vue.set(state.parts[name], 'threads', threads);
      Vue.set(state.parts[name], 'countersinks', countersinks);
    },
    updatePartShipping: (state, { name, shipping }) => {
      Vue.set(state.parts[name], 'shipping', shipping);
    },
    updateOfferShipping: (state, { shipping }) => {
      Vue.set(state, 'currentOfferShipping', shipping);
    },
    setHasImageFlag: (state, { name }) => {
      Vue.set(state.parts[name], 'has_image', true);
    },
    setOfferID: (state, offerID: number) => {
      Vue.set(state, 'currentOfferId', offerID);
    },
    setOffer: (state, { offer_id, shipping, offer_name, status, rod_optimization_cache }) => {
      Vue.set(state, 'currentOfferId', offer_id);
      Vue.set(state, 'currentOfferStatus', status);
      Vue.set(state, 'currentOfferShipping', shipping);
      Vue.set(state, 'currentOfferName', offer_name);
      Vue.set(state, 'optimizationResult', rod_optimization_cache);
    },
    setPart: (state, part) => {
      let new_part = new CustomerPartEntry();
      new_part.setFieldsFromObject(part);

      Vue.set(state.parts, part.name, new_part);
    },
    updateExtractedParams: (state, data) => {
      Vue.set(state, 'extractedParams', []);
      Vue.set(state, 'extractedParams', data);
    },
    updateCurrentOfferSelection: (state, { uuid, selectedIndex, comment }) => {
      Vue.set(state.currentOfferSelection, uuid, {
        selectedIndex: selectedIndex,
        comment: comment
      });
    }
  },
  actions: {
    numSetChanged({ commit, getters }, { count, setUUID, partUUID, ind }) {
      axios
        .post(`${BACKEND_URL}/update_set_count`, {
          offer_id: getters.currentOfferID,
          part_uuid: partUUID,
          count: ind === 0 ? Math.max(count, 1) : count,
          set_uuid: setUUID
        })
        .then(response => {
          commit('setOffer', response.data.offer);
          commit('addSetEntry', {
            setUUID: Object.keys(response.data.set).pop(),
            setEntry: Object.values(response.data.set).pop()
          });
        });
    },
    numSetsChanged({ commit, getters }, { count, part_and_set_uuids }) {
      axios
        .post(`${BACKEND_URL}/update_set_counts`, {
          offer_id: getters.currentOfferID,
          part_and_set_uuids: part_and_set_uuids,
          count: count
        })
        .then(response => {
          commit('setOffer', response.data.offer);
          let sets = response.data['sets'];
          for (let currSet of sets) {
            commit('addSetEntry', {
              setUUID: currSet['set_uuid'],
              setEntry: currSet
            });
          }
        });
    },
    updateOfferComplete: ({ commit }, data) => {
      commit('resetParts');
      let files = data['files'];
      for (let currFile of files) {
        commit('addFileEntry', {
          fileUUID: currFile['file_uuid'],
          fileEntry: { expanded: false, ...currFile }
        });
      }
      const parts = data['parts'].reduce((acc: any, part: any) => ({ ...acc, [part['part_uuid']]: part }), {});
      commit('addParts', parts);
      // for (let currPart of parts) {
      //   commit('addPartEntry', {
      //     partUUID: currPart['part_uuid'],
      //     partEntry: currPart
      //   });
      // }
      const sets = data['sets'].reduce((acc: any, set: any) => ({ ...acc, [set['set_uuid']]: set }), {});
      commit('addSets', sets);
      // for (let currSet of sets) {
      //   commit('addSetEntry', {
      //     setUUID: currSet['set_uuid'],
      //     setEntry: currSet
      //   });
      // }
      if ('offer' in data) {
        commit('setOffer', data['offer']);
      }
    },
    updateOfferDetail({ getters, commit, dispatch }, payload) {
      axios
        .post(`${BACKEND_URL}/update_offer_detail`, {
          offer_id: getters.currentOfferID,
          offer_detail: payload
        })
        .then(response => {
          if (response.status === 200) {
            dispatch('updateOfferComplete', response.data);
          }
        })
        .catch(error => {});
    },
    updateRefreshDefaults: ({ commit }) => {
      axios
        .get(`${BACKEND_URL}/defaults`)
        .then(response => {
          if (response.status === 200) {
            commit('setDefaultValues', {
              disclaimer: response.data.disclaimer,
              maxShortLength: response.data.max_short_length,
              maxLongLength: response.data.max_long_length,
              offerValidityDays: response.data.offer_validity_days
            });
          }
        })
        .catch(error => {});
    },
    updatePartOrder: ({ commit, dispatch }, payload) => {
      commit('updatePartOrder', payload);
      dispatch('updatePartRemote', payload.name);
    },
    updatePartFinish: ({ commit, dispatch }, payload) => {
      commit('updatePartFinish', payload);
      dispatch('updatePartRemote', payload.name);
    },
    updateOfferShipping: ({ commit, dispatch }, payload) => {
      commit('updateOfferShipping', payload);
      dispatch('updateOfferDetail', payload);
    },
    setHasImageFlag: ({ commit, dispatch }, payload) => {
      commit('setHasImageFlag', payload);
      // TODO: too many calls using this... leads to multiple calculations in database
      //dispatch('updatePartRemote', payload.name);
    },
    loadProtoParams: ({ commit }, offer_id) => {
      axios
        .post(`${BACKEND_URL}/param_ext`, {
          offer_id: offer_id
        })
        .then(response => {
          let files = response.data['files'];
          for (let currFile of files) {
            commit('addFileEntry', {
              fileUUID: currFile['file_uuid'],
              fileEntry: { expanded: false, ...currFile }
            });
          }
          let parts = response.data['parts'];
          for (let currPart of parts) {
            commit('addPartEntry', {
              partUUID: currPart['part_uuid'],
              partEntry: currPart
            });
          }
        });
    },
    loadOffer: ({ commit, dispatch }, offer_id) => {
      commit('resetOffer');
      axios
        .post(`${BACKEND_URL}/offer`, {
          offer_id: offer_id
        })
        .then(response => {
          dispatch('updateOfferComplete', response.data).then(res => {});
          router.push('/calc');
        });
    },
    // loadPendingOffer: ({ commit, dispatch }) => {
    //   axios
    //     .get(`${BACKEND_URL}/pending_offer`)
    //     .then(response => {
    //       if (response.status === 200) {
    //         if ('offer_id' in response.data) {
    //           commit('setOffer', {
    //             offer_id: response.data.offer_id,
    //             offer_detail: response.data.offer_detail,
    //             name: response.data.offer_name
    //           });
    //           if (response.data.parts.length > 0) {
    //             dispatch('calculateOffer');
    //           }
    //           for (const part of response.data.parts) {
    //             commit('setPart', part);
    //           }
    //         }
    //       }
    //     })
    //     .catch(error => {});
    // },
    updatePartDetail: ({ state, getters, commit }, { part_uuid, name, value }) => {
      axios
        .post(`${BACKEND_URL}/update_part_property`, {
          part_uuid: part_uuid,
          name: name,
          value: value,
          offer_id: getters.currentOfferID
        })
        .then(response => {
          commit('setOffer', response.data['offer']);
          let part = response.data['part'];
          commit('addPartEntry', {
            partUUID: Object.keys(part).pop(),
            partEntry: Object.values(part).pop()
          });
          let sets = response.data['sets'];
          for (let currSet of sets) {
            commit('addSetEntry', {
              setUUID: currSet['set_uuid'],
              setEntry: currSet
            });
          }
        });
    },
    resetPartDetail: ({ state, getters, commit }, part_uuid) => {
      axios
        .post(`${BACKEND_URL}/reset_part_property`, {
          part_uuid: part_uuid,
          offer_id: getters.currentOfferID
        })
        .then(response => {
          commit('setOffer', response.data['offer']);
          let part = response.data['part'];
          commit('addPartEntry', {
            partUUID: Object.keys(part).pop(),
            partEntry: Object.values(part).pop()
          });
          let sets = response.data['sets'];
          for (let currSet of sets) {
            commit('addSetEntry', {
              setUUID: currSet['set_uuid'],
              setEntry: currSet
            });
          }
        });
    },
    resetPartDetailsForFile: ({ state, getters, commit }, file_uuid) => {
      axios
        .post(`${BACKEND_URL}/reset_part_property_for_file`, {
          file_uuid: file_uuid,
          offer_id: getters.currentOfferID
        })
        .then(response => {
          commit('setOffer', response.data['offer']);
          let parts = response.data['parts'];
          for (let currPart of parts) {
            commit('addPartEntry', {
              partUUID: currPart['part_uuid'],
              partEntry: currPart
            });
          }

          let sets = response.data['sets'];
          for (let currSet of sets) {
            commit('addSetEntry', {
              setUUID: currSet['set_uuid'],
              setEntry: currSet
            });
          }
        });
    },
    updatePartDetailBatch: ({ state, getters, commit }, { part_uuids, values }) => {
      axios
        .post(`${BACKEND_URL}/update_part_properties`, {
          part_uuids: part_uuids,
          values: values,
          offer_id: getters.currentOfferID
        })
        .then(response => {
          commit('setOffer', response.data['offer']);
          let parts = response.data['parts'];
          for (let currPart of parts) {
            commit('addPartEntry', {
              partUUID: currPart['part_uuid'],
              partEntry: currPart
            });
          }
          let sets = response.data['sets'];
          for (let currSet of sets) {
            commit('addSetEntry', {
              setUUID: currSet['set_uuid'],
              setEntry: currSet
            });
          }
        });
    },
    updateOfferedPrice: ({ state, commit }, { pos_id, price }) => {
      axios
        .post(`${BACKEND_URL}/update_offered_price`, {
          pos_id: pos_id,
          offered_price: price
        })
        .then(response => {
          commit('updateOfferedPriceCurrentOffer', price);
        })
        .catch(error => {});
    },
    deleteFile: ({ dispatch, getters }, file_uuid) => {
      axios
        .post(`${BACKEND_URL}/delete_file`, {
          file_uuid: file_uuid,
          offer_id: getters.currentOfferID
        })
        .then(res => {
          dispatch('updateOfferComplete', res.data);
        });
    },
    deletePart: ({ commit, getters }, part_uuid) => {
      axios
        .post(`${BACKEND_URL}/delete_part`, {
          part_uuid: part_uuid,
          offer_id: getters.currentOfferID
        })
        .then(res => {
          commit('resetFiles');
          let files = res.data['files'];
          for (let currFile of files) {
            commit('addFileEntry', {
              fileUUID: currFile['file_uuid'],
              fileEntry: { expanded: false, ...currFile }
            });
          }
          let sets = res.data['sets'];
          for (let currSet of sets) {
            commit('addSetEntry', {
              setUUID: currSet['set_uuid'],
              setEntry: currSet
            });
          }
          let delete_sets = res.data['deleted_set_uuids'];
          for (let currSet of delete_sets) {
            commit('deleteSetEntry', {
              setUUID: currSet
            });
          }
          commit('deletePartEntry', part_uuid);
        });
    },
    loadMaterials: ({ state }) => {
      axios
        .get(`${BACKEND_URL}/materials`)
        .then(ret => {
          Vue.set(state, 'processedMaterials', ret.data.materials);
        })
        .catch(() => {});
    },
    loadDrills: ({ state }) => {
      axios
        .get(`${BACKEND_URL}/available_drills`)
        .then(ret => {
          Vue.set(state, 'availableDrills', ret.data);
        })
        .catch(() => {});
    },
    // calculateOffer: ({ state, commit, dispatch }) => {
    //   axios
    //     .post(`${BACKEND_URL}/calc`, {
    //       offer_id: state.currentOfferId
    //     })
    //     .then(response => {
    //       commit('updateCurrentOffer', response.data);
    //       for (let key in response.data) {
    //         commit('updateOnRequest', {
    //           part_name: key,
    //           on_request_details: response.data[key].on_request
    //         });
    //       }
    //       //dispatch('updateOfferedPrice', response.data.estimated_price);
    //     });
    // },

    deleteCurrentOffer: ({ state, commit }) => {
      axios
        .post(`${BACKEND_URL}/delete_offer`, {
          offer_id: state.currentOfferId
        })
        .then(response => {});
      commit('resetOffer');
    },
    deleteOffer: ({ state, commit }, offerId) => {
      axios
        .post(`${BACKEND_URL}/delete_offer`, {
          offer_id: offerId
        })
        .then(response => {});
    }
  }
});
